:root {
  --blue: #0D6EFD;
  --dark: #0a1929;
  --darker:#112240;
  --yellow: #ffd700;
  --green: #fb8500;
  --light: #fefae0;
  --plain: #ffffff;
  --orange:#64ffda
}

.App {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  justify-content: center;
  font-family:'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.9rem;
  line-height: 1.6rem;
  font-weight: 300;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.jumbotron {
  min-height: 90vh;
  background-image: linear-gradient(0deg, rgba(10, 25, 41, 0.7), rgba(10, 25, 41, 0.7)), url('./images/woodendesk.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*styling for chevron*/
.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  /* margin-left: 40px; */
  margin-top: 20px;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;



}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: var(--orange);
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;

  }

  33% {
    opacity: 1;
    transform: translateY(30px);
  }

  67% {
    opacity: 1;
    transform: translateY(40px);
  }

  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.chevronContainer {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

/*end of styling for chevron*/

/*typewriter styling starts*/
.typewriter {
  font-family: monospace;
  font-size: 4rem;
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: .1em;
  /* Adjust as needed */
  animation:
    typing 3.5s steps(30, end),
    blink-caret .5s step-end infinite;
}

@media screen and (max-width: 1146px) {
  .typewriter {
    font-size: 3rem;
  }
}

@media screen and (max-width: 906px) {
  .typewriter {
    font-size: 2rem;
  }
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: rgba(163, 140, 39, 0)
  }
}

/*typewriter styling ends*/

/* top nav styling */
.topNavDiv {
  background-color: var(--dark);
  position: sticky;
  top:0;
  z-index: 100;
}

.topnav {
  overflow: hidden;
  margin:0;
  padding: 0;
  padding-top: 5px;
}

.topnav li {
  float: left;
  display: block;
  padding: 0.8rem 2rem;
}

.topnav a {
  color: #ffffff8c;
  text-align: center;
  font-size: 15px;
  text-decoration: none;
  font-weight: bold;
}
.topnav a:hover {
  color: var(--orange);
}
.topnav a:active {
  color: var(--orange) !important;  
}
.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav li {
    display: none;
  }
  .topnav li.icon {    
    display: block;
    text-align: center;

  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
    padding: 0px
  }
  .topnav.responsive li {
    float: none;
    display: block;
    text-align: center;
  }
  .topNavDiv.text-center > ul {
    padding: 0;
  }
}

/* project cards */
.projectCard {
  border-radius: 10px; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}
.projectCard:hover {  
  margin: 0px;
  border:none;
  animation-name: bigger;
  animation-duration: 4s;
  animation-timing-function: ease;
}
@keyframes bigger {
  from {box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);}
  to {box-shadow: 0 4px 8px 0 rgba(255, 133, 0, 0.2), 0 6px 20px 0 rgba(251, 133, 0, 0.2);}
}
.iconCol {
  color:var(--blue)
}

.btn-custom {
  background-image: linear-gradient(to right, var(--orange), var(--orange));
  color:var(--dark) !important;
  border:none !important;
}
.btn-customDark {
  background-image: linear-gradient(to right, var(--dark), var(--dark));
  color:var(--orange) !important;
  border:none !important;
}
.btn-customLight {
  background-image: linear-gradient(to right, var(--orange), var(--orange));
  color:var(--dark) !important;
  border:none !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color:var(--orange) !important;
  font-weight: 400;
  background: var(--dark) !important;
}

div.card-body {
  color:var(--dark)
}